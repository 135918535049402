<template>
  <div class="about">
    <Header page="about"/>
    <div class="grid grid:3">
      <Nav/>
      <Hero message="Hey !"/>
    </div>
    <div class="flex column gap--big">
      <div class="box">
        <h1>Thibaud d'Arros</h1>
        <p class="flex">
          <b><i class="fab fa-github"></i>&nbsp; Athios-dev</b>
          <b><i class="fas fa-map-marker-alt"></i>&nbsp; Bordeaux, France</b>
        </p>
        <p>Hey, je suis Thibaud d'Arros, développeur web full-stack, formateur à Bordeaux (33) et fondateur de <a href="https://laconsole.dev" target="_blank">laConsole</a>.</p>
        <hr>
        <blockquote class="flex align-center">
          <i class="fas fa-quote-left fa-2x"></i>
          <span>Je crois fermement en l'avenir de la Jamstack et des headless CMS.</span>
        </blockquote>
      </div>
      <div class="flex gap--big">
        <div class="box">
          <p>J'enseigne diverses <router-link to="/teaching">formations</router-link> dans des écoles reconnues comme Digital Campus, 3W Academy, WebTech Institute, Campus du Lac, EFREI, EPSI, WIS, ESG ou encore LISAA. Je développe également des sites web sur mesure pour mes clients.</p>
        </div>
        <router-link to="/contact" class="btn btn--secondary btn--vertical text-center anim-pop">Contact</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default { name: 'About' }
</script>

<style scoped>
  p:first-child { margin-top: 0; }
  p:last-child { margin-bottom: 0; }
  p:only-child { margin: 0; }
  hr {
    border: none;
    border-top: solid var(--text) 1px;
  }
  blockquote {
    margin: 0;
    font-style: italic;
    font-size: .875rem;
  }
  .btn {
    font-weight: 700;
    font-size: 1.25rem;
    letter-spacing: 1px;
  }
</style>
