<template>
  <header class="header flex justify-between align-center">
    <span class="cli"><i class="fas fa-chevron-right"></i> cd&nbsp; <span class="text-secondary">{{ page }}</span><span class="blink">_</span></span>
    <nav class="flex gap--tiny">
      <a class="btn btn--tiny btn--primary btn--outlined" href="https://github.com/Athios-dev" target="_blank"><i class="fab fa-github fa-lg"></i></a>
      <a class="btn btn--tiny btn--primary btn--outlined" href="https://www.linkedin.com/in/thibaud-d-arros/" target="_blank"><i class="fab fa-linkedin-in fa-lg"></i></a>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: {
    page: String
  }
}
</script>

<style scoped>
  .blink { animation: blink 1.5s ease infinite; }
  .header { margin-bottom: 2rem; }
  .cli {
    font-size: 1.75rem;
    font-weight: 600;
  }
  .fa-chevron-right { font-size: 1.75rem; }
  @media screen and (min-width: 500px) {
    .cli { font-size: 2rem; }
  }
</style>