<template>
  <aside class="hero relative col:2" style="align-self:end;">
    <img class="img-full" src="../assets/thibaud-d-arros.svg" alt="Illustration - Thibaud d'Arros">
    <span class="message absolute">{{ message }}</span>
  </aside>
</template>

<script>
  export default {
    name: 'Hero',
    props: {
      message: String
    }
  }
</script>

<style scoped>
  .message {
    top: 4%;
    left: 26%;
    transform: rotateZ(-10deg);
    font-family: var(--typoFun);
    letter-spacing: 1px;
    word-spacing: 1px;
    user-select: none;
  }
  img {
    margin-bottom: -2rem;
    user-select: none;
  }
  @media screen and (min-width: 400px) {
    img { margin-bottom: -3rem; }
  }
  @media screen and (min-width: 500px) {
    .message { font-size: 1.25rem; }
  }
</style>