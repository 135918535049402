import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// GLOBAL components
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Nav from './components/Nav.vue'
import Hero from './components/Hero.vue'

// STYLE and SCRIPTS
import '@/assets/js/anim-domain.js'
import '@/assets/js/cursor.js'
import '@/assets/css/cursor.css'
import '@/assets/css/neon.css'

const app = createApp(App).use(router)

app.component('Header', Header)
app.component('Nav', Nav)
app.component('Hero', Hero)
app.component('Footer', Footer)

app.mount('#app')