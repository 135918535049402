<template>
  <router-link :to="path" class="nav-link">
    <span class="link-info">
      <span class="link-name">{{ name }}</span>
      <span class="link-line">
        <span v-for="n in (16 - name.length)" :key="n">-</span>
      </span>
    </span>
    <i :class="icon"></i>
  </router-link>
</template>

<script>
  export default {
    name: 'NavLink',
    props: {
      name: String,
      icon: String,
      path: String
    }
  }
</script>

<style scoped>
  .nav-link { position: relative; }
  .link-info {
    position: absolute;
    left: -190px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    display: none;
    text-align: right;
    color: var(--text);
  }
  .link-name {
    padding: .25rem .5rem;
    border: solid 1px var(--text);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: .875rem;
  }
  a {
    color: var(--background);
    transition: .5s;
  }
  a:not(.router-link-exact-active):hover {
    color: var(--text);
    transform:scale(1.1);
  }
  a.router-link-exact-active {
    color: var(--text);
    transform:scale(1.1)
  }
  a:not(.router-link-exact-active):hover span,
  a.router-link-exact-active .link-info { visibility: visible; }
  i {
    text-shadow: 0 0 5rem var(--primary);
    font-size: 2.25rem;
  }
  @media screen and (min-width: 400px) {
    i { font-size: 2.5rem; }
  }
  @media screen and (min-width: 500px) {
    i { font-size: 3rem; }
  }
  @media screen and (min-width: 900px) {
    .link-info { display: block; }
  }
</style>
