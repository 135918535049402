document.addEventListener("DOMContentLoaded", function() {
  typing(200);
});

function typing(delay) {
  const domainName = document.querySelector('.domain');
  const letters = ['a', 't', 'h', 'i', 'o', 's', '.', 'd', 'e', 'v'];
  let i = 0;
  let typer = setInterval(function() {
    if (i < letters.length) {
      domainName.append(letters[i]);
      i++;
    } else {
      clearInterval(typer);
    }
  }, delay);
}