<template>
  <div class="overlay">
    <span class="go-top" @click="scrollTop">
      <i class="fas fa-angle-double-up"></i>
    </span>
    <div class="neon">
      <div class="container">
        <router-view/>
        <Footer/>
      </div>
    </div>
    <div class="domain">
      <span class="domain-word"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods:{
    scrollTop() {
      window.scrollTo(0,0);
    }
  }
}
</script>

<style>
  :root { 
    --typoFun: 'Knewave', cursive;
  }
  h1 {
    font-size: 1.8rem;
  }
  body { overflow-x: hidden; }
  .domain {
    position: fixed;
    bottom: 15%;
    left: 1rem;
    font-size: 5rem;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    z-index: -1000;
    color: var(--background);
    text-shadow: -2px 0 var(--primaryShadow), 0 2px var(--primaryShadow), 2px 0 var(--primaryShadow), 0 -2px var(--primaryShadow);
    padding-bottom: 1rem;
    border-bottom: .15em solid var(--secondaryShadow);
    animation: blink-caret 1s step-end infinite;
    transform: rotateZ(180deg);
    display: none;
  }
  .go-top {
    position: fixed;
    bottom: 2rem;
    right: 1rem;
    background-color: var(--primaryShadow);
    border: solid 1px var(--primary);
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s;
    display: none;
  }
  .go-top:hover {
    cursor: pointer;
    background-color: var(--primary);
    animation: upAndDown .75s forwards infinite ease;
  }
  .go-top i {
    color: var(--background);
    font-size: 3rem;
  }

  .anim-pop {
    animation-name: pop;
    animation-duration: 10s;
    animation-delay: 3s;
    animation-iteration-count: infinite;
  }
  .anim-rotate {
    animation-name: rotate;
    animation-duration: 10s;
    animation-delay: 3s;
    animation-iteration-count: infinite;
  }

  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: var(--secondaryShadow); }
  }

  /* The "go top" hover effect */
  @keyframes upAndDown {
    from, to { transform: translateY(0); }
    50% { transform: translateY(-.5rem); }
  }

  /* The button scaling effect */
  @keyframes pop {
    2%   { transform: scale(.95); }
    5%   { transform: scale(1.05); }
    8%   { transform: scale(1); }
  }

  /* The button rotate effect */
  @keyframes rotate {
    2% { transform: rotate(-1deg); }
    5% { transform: rotate(2deg); }
    8% { transform: rotate(0deg); }
  }
  
  @media screen and (min-width: 900px) {
    .domain { display: block; }
    .go-top { display: flex; }
  }
</style>
