<template>
  <footer class="footer text-center">
    <router-link to="/privacy-policy"><i class="fas fa-bookmark"></i>&nbsp; Politique de confidentialité</router-link>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
  .footer {
    margin-top: 2rem;
  }
</style>