<template>
  <div class="formations">
    <Header page="teaching"/>
    <div class="grid grid:3">
      <Nav/>
      <Hero message="Let's dev !"/>
    </div>
    <div class="flex column gap--big">
      <div class="box">
        <h1>Formations</h1>
        <p>Je propose {{ courses.length }} formations en développement web.</p>
        <div class="flex gap--tiny wrap">
          <button class="btn btn--tiny btn--secondary" :class="{ 'btn--outlined': !activeFilter.all }" @click="toggle('all')">Toutes ({{ courses.length }})</button>
          <button class="btn btn--tiny btn--secondary" :class="{ 'btn--outlined': !activeFilter.language }" @click="toggle('language')">Langages ({{ countByCategory('language') }})</button>
          <button class="btn btn--tiny btn--secondary" :class="{ 'btn--outlined': !activeFilter.technology }" @click="toggle('technology')">Technologies ({{ countByCategory('technology') }})</button>
          <button class="btn btn--tiny btn--secondary" :class="{ 'btn--outlined': !activeFilter.noCode }" @click="toggle('noCode')">No Code ({{ countByCategory('noCode') }})</button>
          <button class="btn btn--tiny btn--secondary" :class="{ 'btn--outlined': !activeFilter.guide }" @click="toggle('guide')">Guides ({{ countByCategory('guide') }})</button>
        </div>
      </div>
      <div class="flex column gap--big">
        <Course v-for="(course, index) in courses" :key="index" :icon="course.icon" :title="course.title" :description="course.description" :level="course.level" :opened="course.opened" :category="course.category.plain" :class="{ 'hide': isVisible(course.category.slug) }"/>
      </div>
    </div>
  </div>
</template>

<script>
  import Course from '@/components/Course.vue'
  export default {
    name: 'teaching',
    components: { Course },
    data() {
      return {
        activeFilter: {
          all: true,
          language: false,
          technology: false,
          noCode: false,
          guide: false
        },
        courses: [
          {
            icon: "fab fa-wordpress-simple",
            title: "Créer un site avec WordPress",
            description: "À l'origine de plus de 42% des sites web mondiaux, WordPress est aujourd'hui la référence mondiale des CMS Open-Source.",
            level: 1,
            opened: false, 
            category: {
              slug: 'noCode',
              plain: 'No Code'
            }
          },
          {
            icon: "fab fa-bootstrap",
            title: "Apprendre Bootstrap",
            description: "Bootstrap est le framework CSS de référence. Il s'appuie sur l'utilisation de composants, à l'inverse de son principal concurrent, Tailwind CSS, qui est quant à lui basé sur une approche \"utilities-first\".",
            level: 1,
            opened: false, 
            category: {
              slug: 'technology',
              plain: 'Technologie'
            }
          },
          {
            icon: "fas fa-robot",
            title: "Initiation à l'algorithmique",
            description: "L'algorithmique est le processus consistant à résoudre un problème, en y décrivant précisemment ses étapes de résolution. Cette compétence est essentielle à la compréhension et utilisation de langages dits de programmation.",
            level: 1,
            opened: false, 
            category: {
              slug: 'guide',
              plain: 'Guides'
            }
          },
          {
            icon: "fab fa-symfony",
            title: "Apprendre Symfony",
            description: "Symfony est la référence mondiale des frameworks PHP. Français et basé sur une architecture MVC, il est une alternative à Laravel.",
            level: 3,
            opened: false, 
            category: {
              slug: 'technology',
              plain: 'Technologie'
            }
          },
          {
            icon: "fas fa-book",
            title: "Rédiger un Cahier des Charges pour site web",
            description: "De la contextualisation du projet à sa description fonctionnelle détaillée, en passant par ses spécificités graphiques et techniques, le cahier des charges est un document essentiel à la création d'un site web.",
            level: 1,
            opened: false, 
            category: {
              slug: 'guide',
              plain: 'Guides'
            }
          },
          {
            icon: "fab fa-vuejs",
            title: "Apprendre Vue.js",
            description: "Le framework Vue constitue avec React et Angular, le trio de référence des frameworks JS. Il est utilisé dans de très nombreux projets basés sur la Jamstack.",
            level: 3,
            opened: false, 
            category: {
              slug: 'technology',
              plain: 'Technologie'
            }
          },
          {
            icon: "fab fa-node-js",
            title: "Apprendre Node.js / Express",
            description: "Node.js est l'environnement d'exécution incontournable sur lequel reposent les principales nouvelles technologies back-end JavaScript.",
            level: 3,
            opened: false, 
            category: {
              slug: 'technology',
              plain: 'Technologie'
            }
          },
          {
            icon: "fab fa-git",
            title: "Le versionning avec Git et GitHub",
            description: "Git est le logiciel de versionning le plus répandu au monde. Couplé à des plateformes comme GitHub, GitLab ou encore Bitbucket : la gestion de version, la colaboration et l'hébergement en sont grandement simplifiés.",
            level: 2,
            opened: false, 
            category: {
              slug: 'guide',
              plain: 'Guides'
            }
          },
          {
            icon: "fas fa-cogs",
            title: "Booster son workflow front-end",
            description: "Améliorer son workflow front-end avec les pré-processeurs CSS, post-processeurs CSS, gestionnaires de dépendances, outils de versionning, moteurs de templates, bundlers ou encore tasks runners est aujourd'hui essentiel.",
            level: 3,
            opened: false, 
            category: {
              slug: 'technology',
              plain: 'Technologie'
            }
          },
          {
            icon: "fas fa-database",
            title: "Apprendre le langage SQL",
            description: "SQL est le célèbre langage de requêtage permettant d'interragir (création, modification, suppression et récupération de données) avec des bases de données relationnelles, dont le principal Système de Gestion est MySQL.",
            level: 1,
            opened: false, 
            category: {
              slug: 'language',
              plain: 'Langages'
            }
          },
          {
            icon: "fab fa-php",
            title: "Apprendre le PHP",
            description: "Le PHP est le langage de programmation back-end par excellence. Historique, il fait tourner la plupart des sites web, ainsi que le célèbre CMS WordPress.",
            level: 2,
            opened: false, 
            category: {
              slug: 'language',
              plain: 'Langages'
            }
          },
          {
            icon: "fas fa-sitemap",
            title: "UML pour les développeurs",
            description: "UML est un langage de modélisation graphique à base de pictogrammes conçu pour détailler des systèmes informatiques. Il est très utile pour la spécification fonctionnelle et technique d'une application.",
            level: 1,
            opened: false, 
            category: {
              slug: 'guide',
              plain: 'Guides'
            }
          },
          {
            icon: "fab fa-js-square",
            title: "Apprendre le JavaScript",
            description: "Le JavaScript est le langage de programmation web le plus tendance. S'éxécutant à l'origine uniquement côté client, il a été créé pour animer et dynamiser des pages web. Aujourd'hui il est aussi capable de s'exécuter sur un serveur, avec des technologies comme Node.js.",
            level: 2,
            opened: false, 
            category: {
              slug: 'language',
              plain: 'Langages'
            }
          },
          {
            icon: "fab fa-html5",
            title: "Apprendre le HTML",
            description: "Le HTML est le langage de balisage utilisé pour créer la structure d'une page web.",
            level: 1,
            opened: false, 
            category: {
              slug: 'language',
              plain: 'Langages'
            }
          },
          {
            icon: "fab fa-wordpress-simple",
            title: "Développer un thème WordPress",
            description: "Si WordPress propose une immense banque de thèmes gratuits ou payants, aucun n'apportera autant de personnalisation qu'un thème sur-mesure.",
            level: 2,
            opened: false, 
            category: {
              slug: 'noCode',
              plain: 'No Code'
            }
          },
          {
            icon: "fab fa-css3-alt",
            title: "Apprendre le CSS",
            description: "Le CSS est le langage de mise en forme utilisé pour customiser les composants web HTML.",
            level: 1,
            opened: false, 
            category: {
              slug: 'language',
              plain: 'Langages'
            }
          },
          {
            icon: "fas fa-globe",
            title: "Comprendre le web",
            description: "Le web est la composante principale d'Internet. Derrière bon nombre d'outils que nous utilisons au quotidien, il est important d'en comprendre son fonctionnement (architecture, ecosystème, transport des messages, etc.).",
            level: 1,
            opened: false, 
            category: {
              slug: 'guide',
              plain: 'Guides'
            }
          },
          {
            icon: "fas fa-dollar-sign",
            title: "Apprendre jQuery",
            description: "jQuery est une bibliothèque JavaScript incontournable libre et multiplateforme créée pour faciliter l'écriture de scripts côté client.",
            level: 2,
            opened: false, 
            category: {
              slug: 'technology',
              plain: 'Technologie'
            }
          }
        ]
      }
    },
    methods: {
      isVisible(filter) {
        if (this.activeFilter[filter] == false && this.activeFilter.all == false) {
          return true;
        }
      },
      toggle(newFilter) {
        if (this.activeFilter[newFilter] == false) {
          let filters = Object.keys(this.activeFilter);
          filters.forEach((filter) => {
            if (filter != newFilter) {
              this.activeFilter[filter] = false;
            }
          });
          this.activeFilter[newFilter] = !this.activeFilter[newFilter];
        }
      },
      countByCategory(category) {
        let i = 0;
        this.courses.forEach((course) => {
          if (course.category.slug == category) {
            i++;
          }
        });
        return i;
      }
    }
  }
</script>

<style scoped>
  p:first-child { margin-top: 0; }
  p:last-child { margin-bottom: 0; }
  p:only-child { margin: 0; }
  .hide { display: none; }
</style>