<template>
  <div class="nav" style="align-self:start">
    <nav class="col:1 flex column justify-center align-center"> 
      <NavLink v-for="(link, index) in links" :key="index" :name="link.name" :icon="link.icon" :path="link.path"/>
    </nav>
  </div>
</template>

<script>
  import NavLink from '@/components/NavLink.vue'
  export default {
    name: 'Nav',
    components: { NavLink },
    props: {},
    data() {
      return {
        links: [
          {
            name: "A propos",
            icon: "fas fa-address-card",
            path: "/"
          },
          {
            name: "Formations",
            icon: "fas fa-laptop-code",
            path: "/teaching"
          },
          {
            name: "Contact",
            icon: "fas fa-paper-plane",
            path: "/contact"
          }
        ]
      }
    }
  }
</script>

<style scoped>
  .nav {
    gap: 1.25rem;
    margin-bottom: 2rem;
  }
  nav {
    height: 100%;
    gap: 1.5rem;
  }
  @media screen and (min-width: 400px) {
    .nav { gap: 1.5rem; }
  }
  @media screen and (min-width: 500px) {
    .nav { gap: 2rem; }
  }
</style>