<template>
  <div class="course box flex gap--big">
    <div class="icon flex justify-center" v-show="!this.stateOpened">
      <i class="fa-4x" :class="icon"></i>
    </div>
    <div class="decription">
      <h2>{{ title }}</h2>
      <span class="toggle" @click="this.stateOpened = !stateOpened" v-show="!this.stateOpened">Plus d'infos</span>
      <span class="toggle" @click="this.stateOpened = !stateOpened" v-show="this.stateOpened">Masquer</span>
      <div v-if="stateOpened">
        <p>{{ description }}</p>
        <hr>
        <div class="level flex align-center">
          <div class="stars flex">
            <template v-for="n in 3" :key="n" >
              <span v-if="n <= level"><i class="fas fa-star"></i></span>
              <span v-else><i class="far fa-star"></i></span>
            </template>
          </div>
          <span>{{ getStrLevel(level) }}</span>
        </div>
        <div class="category flex align-center">
          <i class="fas fa-tag text-secondary"></i>
          <span>{{ category }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Course',
    props: {
      icon: String,
      title: String,
      description: String,
      level: Number,
      opened: Boolean,
      category: String
    },
    data() {
      return {
        stateOpened: this.opened
      }
    },
    methods: {
      getStrLevel(level) {
        const levels = {
          1: "Débutant",
          2: "Intermédiaire",
          3: "Avancé"
        }
        return levels[level];
      }
    }
  }
</script>

<style scoped>
  h2 {
    margin-top: 0;
    margin-bottom: .25rem;
    font-size: 1.25rem;
  }
  p:last-child {
    margin-bottom: 0;
    font-size: .875rem;
  }
  hr {
    border: none;
    border-top: 1px solid var(--secondary);
  }
  .icon { min-width: 80px; }
  .toggle {
    color: var(--secondary);
    cursor: pointer;
  }
  .level, .category { gap: .75rem; }
  .stars { gap: .25rem; }
  .fa-star { color: var(--secondary); }
</style>