<template>
  <div class="contact">
    <Header page="contact"/>
    <div class="grid grid:3">
      <Nav/>
      <Hero message="Get in touch !"/>
    </div>
    <div class="flex column gap--big">
      <div class="box">
        <h1>Contact</h1>
          <p>Vous êtes une entreprise ou une école à la recherche d'un formateur en développement web ?</p>
          <p>N'hésitez pas à me contacter, je me ferai un plaisir de vous répondre !</p>
      </div>
      <button v-if="!visible" class="btn btn--primary btn--big text-center text-upper anim-rotate" @click="showInfos">Me contacter</button>
      <template v-else>
        <a href="tel:+33675405449" class="btn btn--secondary text-center">
          <i class="fas fa-phone"></i> 06 75 40 54 49
        </a>
        <a href="mailto:thibaud.arros@gmail.com" class="btn btn--secondary text-center">
          <i class="fas fa-envelope"></i> thibaud.arros@gmail.com
        </a>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'contact',
    data() {
      return {
        visible: false
      }
    },
    methods: {
      showInfos() {
        this.visible = !this.visible;
      }
    }
  }
</script>


<style scoped>
  p:first-child { margin-top: 0; }
  p:last-child { margin-bottom: 0; }
  p:only-child { margin: 0; }
  .btn {
    font-weight: 700;
    font-size: 1.25rem;
    letter-spacing: 1px;
  }
</style>
